
// Import
var jQuery = require ('striata-jquery');
var $ = jQuery;


(function ($)
{
	//Init form compare for the list of forms to compare
	$.fn.extend ({
		InitFormCompare: function (resetForms)
		{
			if (!GetFormCheckListing() || resetForms)
			{
				$(this).data ('forms', []);
			}
		}
	});

	$.fn.extend({
		ClearFormCompare: function()
		{
			return this.each (function()
			{
				$(this).data ('forms', []);
			});
		}
	});

	$.fn.extend({
		AddFormCompare: function (form, data, callback)
		{
			//NOTE: check if form ID passed and the form exists on the current page we checking
			var formElement;
			var currentElement;
			var formReference = form;

			if (form === '')
			{
				return false;
			}

			if (form && form.nodeName)
			{
				formElement = $(form);
				currentElement = form;
				formReference = form;
			}
			else
			{
				formElement = $("#" + form);
				currentElement = document.getElementById (form);
			}

			var subElements = [];

			var subElement = {
				id       : "",
				display  : "",
				readonly : "",
			};

			// Persist the state of the form elements.
			if (formElement.length > 0)
			{
				var formSubElements = currentElement.querySelectorAll ("*");

				if ($(this).data ('forms'))
				{
					for (var index in formSubElements)
					{
						if (formSubElements[index].nodeType == 1)
						{
							var formSubElement = Object.create (subElement);

							formSubElement.id = formSubElements[index].id;
							formSubElement.display = window.getComputedStyle (formSubElements[index]).display;
							formSubElement.readonly = formSubElements[index].hasAttribute ("readonly");

							if (formSubElement.id && formSubElement.display)
							{
								subElements.push (formSubElement);
							}
						}
					}

					var formData = {
						form        : formReference,
						data        : data,
						subElements : subElements,
					};

					$(this).data ('forms').push (formData);
				}
			}

			return true;
		}
	});


	//here bind the form unload event note there are two different types
	//we have our build form post we need to check and the onbeforeunload to check on
	$.fn.extend({
		SetFormCompare: function (callBack, callBackParameters)
		{
			function CompareForm()
			{
				var forms = $(this).data ('forms');

				for (var formData in forms)
				{
					var form = forms[formData].form;
					var data = forms[formData].data;

					//call the form compare function
					var hasChanges = GetFormHasChanges (form);
					if (hasChanges)
					{
						//here the form has changes and we should not allow the person to carry on
						return data;
					}
				}
			}

			function GetElementChangedId()
			{
				var forms = $(this).data ('forms');
				var elementId;

				for (var formData in forms)
				{
					var form = forms[formData].form;
					elementId = GetFormHasChanges (form, true);

					if (typeof elementId !== "undefined")
					{
						break;
					}
				}

				return elementId;
			}

			//here we bind to the beforeunload as this is to control Back Forward Refresh and Close
			$(window).on ("beforeunload", function (event)
			{
				//here call the formcompare function if form fails return str and then focus on form
				var isChanges = CompareForm();

				if (isChanges != 'undefined')
				{
					//here there are changes kill all spinners as the ajax failed
					$(this).ThrobberHide();
				}

				return isChanges;
			});

			//if it wasnt a form load it must be a move to somewhere else
			if (callBack)
			{
				//here we have a call back function we need to call it
				var data = CompareForm();

				if (data)
				{
					//here set the message to display
					$("#leaveContent").replaceWith (data);

					//set the buttons here to active just incase cause of a deactive of buttons
					$("#YesFormLeaveSaveButton").removeClass ('temporarilyDisabledMarker disabledButton');
					$("#NoFormLeaveSaveButton").removeClass ('temporarilyDisabledMarker disabledButton');
					$("#YesFormLeaveSaveButton").removeAttr ("disabled");
					$("#NoFormLeaveSaveButton").removeAttr ("disabled");

					//if the data is set it means there was a change and we need the bind the function to the yes key
					$(window).Modal ("#leaveform", "#modalTemplateCloseCreateCancelButton, #modalTemplateCloseCreateXButton, #NoFormLeaveSaveButton",
					{
						"position"    : "center",
						"offsetTop"   : 0,
						"offsetSides" : 0
					});

					$("#YesFormLeaveSaveButton").on ('click', function()
					{
						//remove all buttons that could cause issue
						$(".groupButton").removeClass ('temporarilyDisabledMarker disabledButton');
						$(".groupButton").removeAttr ("disabled");

						$(this).CloseModalWindow ("#leaveform");
						$(window).off ("beforeunload");

						var callParams = callBackParameters;
						callBackParameters = new Array();

						callBack.apply (this, callParams);
						callBack = function(){};
						$(this).off ("click");
					});

					$("#NoFormLeaveSaveButton").on ('click', function()
					{
						//here we need to figure out where to focus
						$(".groupButton").removeClass ('temporarilyDisabledMarker disabledButton');
						$(".groupButton").removeAttr ("disabled");

						callBackParameters = new Array();

						var changedElementId = GetElementChangedId();

						if (typeof changedElementId !== "undefined")
						{
							//here we found an element Id to focus on
							$('#' + changedElementId).trigger ('focus');
						}

						callBack = function(){};
						$(this).off ("click");
					});
				}
				else
				{
					//here call the function passed so we can carry on
					$(window).off ("beforeunload");

					var callParams = callBackParameters;
					callBackParameters = new Array();

					callBack.apply (this, callParams);
					callBack = function(){};
				}
			}
		}
	});

})(jQuery);


/*
FormCompare
Workings
--------
Take Form ID
return if same or changed

*/

function GetFormCheckListing()
{
	var forms = $(this).data ('forms');
	return forms;
}

function GetFormHasChanges (form, getElementId)
{
	var hasChanges = false;
	var elementId;

	//cant do it unless its in the form compare
	var forms = $(this).data ('forms');
	var currentForm;
	var formElement = GetFormElement (form);

	for (var formData in forms)
	{
		var testForm = forms[formData].form;
		var testFormElement;

		if (form)
		{
			currentForm = form && form.id ? form.id : form;
			currentForm = currentForm.id ? $(currentForm) : $("#" + currentForm);
		}

		if (testForm && testForm.nodeName)
		{
			testFormElement = $(testForm);
		}

		if (testForm && testForm.nodeName)
		{
			testFormElement = $(testForm);
		}
		else
		{
			testFormElement = $("#" + testForm);
		}

		if (testFormElement.is (formElement))
		{
			currentForm = testFormElement;
			break;
		}
	}

	if (!currentForm)
	{
		return hasChanges;
	}

	var formElementCollection = currentForm.find ("input, textarea, select, table, div, span");

	formElementCollection.each (function (index)
	{
		if (!$(formElementCollection[index]).hasClass ('disabledFormCompare'))
		{
			formElementCollection[index].type = formElementCollection[index].type || formElementCollection[index].getAttribute ("type");

			if ((formElementCollection[index].type == "text" ||
					formElementCollection[index].type == "file" ||
					formElementCollection[index].type =="password" ||
					formElementCollection[index].type == "textarea" ||
					formElementCollection[index].type == "range") &&
					formElementCollection[index].defaultValue != formElementCollection[index].value)
			{

				if (!(formElementCollection[index].type == "file" && !$(formElementCollection[index]).val()))
				{
					hasChanges = true;
					elementId = formElementCollection[index].id;
					return hasChanges;
				}
			}

			if ((formElementCollection[index].type == "checkbox" ||
					formElementCollection[index].type == "radio") &&
					formElementCollection[index].defaultChecked != formElementCollection[index].checked)
			{
				hasChanges = true;
				elementId = formElementCollection[index].id;
				return hasChanges;
			}

			if ((formElementCollection[index].type == "select-one" || formElementCollection[index].type == "select-multiple"))
			{
				//note formElementCollection[index] is a special cause as a value needs to be selected and if not causes error

				var selected = 0;

				if (document.getElementById (formElementCollection[index].id))
				{
					selected = document.getElementById (formElementCollection[index].id).selectedIndex;
				}

				//assumption made here if element
				for (var x = 0; x < formElementCollection[index].length; x++)
				{
					if (selected == 0 && formElementCollection[index].options[x].defaultSelected == false)
					{
						//here we on first element and default value false
						//more than likely a selected was not chosen
					}
					else
					{
						if (formElementCollection[index].options[x].selected != formElementCollection[index].options[x].defaultSelected)
						{
							hasChanges = true;
							elementId = formElementCollection[index].id;
							return hasChanges;
						}
					}
				}
			}

			if (formElementCollection[index].type == "tags")
			{
				var stringValue = formElementCollection[index].getAttribute ("value") || "";
				var valueArray = stringValue.split (",");

				var inputValue;
				if (valueArray.length > 0)
				{
					inputValue = valueArray;
				}
				else
				{
					inputValue = formElementCollection[index].value;
				}

				formElementCollection[index].value = inputValue;

				if (!formElementCollection[index].defaultValue)
				{
					formElementCollection[index].defaultValue = [];
				}

				if (formElementCollection[index].defaultValue.toString() != formElementCollection[index].value.toString())
				{
					hasChanges = true;
					elementId = formElementCollection[index].id;
					return hasChanges;
				}
			}

			if (formElementCollection[index].getAttribute ("formupdated") == "true")
			{
				hasChanges = true;
				elementId = formElementCollection[index].id;
				return hasChanges;
			}
		}
	});

	if (getElementId)
	{
		return elementId;
	}

	return hasChanges;
}

/*
UpdateFormDetails
WOrkings
--------
Takes a form id and set all the default values when processed
*/

function UpdateFormDetails (form)
{
	var formElement = GetFormElement (form);

	if (!formElement || !GetFormHasChanges (form))
	{
		return false;
	}

	formElement.data ("MonitorFormLastChangeState", "");
	var formElementCollection = formElement.find ("input, textarea, select, table, div, span");

	formElementCollection.each (function (index)
	{
		if (!$(formElementCollection[index]).hasClass ('disabledFormCompare'))
		{
			formElementCollection[index].type = formElementCollection[index].type || formElementCollection[index].getAttribute ("type");

			//need to check if we have a name or id cause it could just be a blank form
			if ((formElementCollection[index].type == "text" ||
					formElementCollection[index].type == "password" ||
					formElementCollection[index].type == "textarea" ||
					formElementCollection[index].type == "file" ||
					formElementCollection[index].type == "range"))
			{
				formElementCollection[index].defaultValue = formElementCollection[index].value;
			}

			if ((formElementCollection[index].type == "radio" ||
					formElementCollection[index].type == "checkbox") &&
					formElementCollection[index].defaultChecked != formElementCollection[index].checked)
			{
				formElementCollection[index].defaultChecked = formElementCollection[index].checked;
			}

			if ((formElementCollection[index].type == "select-one" || formElementCollection[index].type == "select-multiple"))
			{
				for (var x = 0; x < formElementCollection[index].length; x++)
				{
					if (formElementCollection[index].options[x].selected != formElementCollection[index].options[x].defaultSelected)
					{
						formElementCollection[index].options[x].defaultSelected = formElementCollection[index].options[x].selected;
					}
				}
			}

			if (formElementCollection[index].type == "tags")
			{
				var inputValue = [];

				var stringValue = formElementCollection[index].getAttribute ("value") || "";
				var valueArray = stringValue.split (",");

				if (valueArray.length > 0)
				{
					inputValue = valueArray;
				}
				else
				{
					inputValue = formElementCollection[index].value;
				}

				formElementCollection[index].type = formElementCollection[index].type || formElementCollection[index].getAttribute ("type");

				if (inputValue)
				{
					formElementCollection[index].value = inputValue;
				}

				formElementCollection[index].defaultValue = inputValue;
			}

			if (formElementCollection[index].getAttribute ("formupdated") == "true")
			{
				formElementCollection[index].removeAttribute ("formupdated");
			}
		}
	});

	// Update the persisted form elements state.
	if (formElement.length > 0)
	{
		for (var index in $(this).data ('forms'))
		{
			if ($(this).data ('forms')[index].form === form)
			{
				var formElements = formElement[0].querySelectorAll ("*");

				for (var elementIndex in formElements)
				{
					if (formElements[elementIndex].id)
					{
						for (var subElementIndex in $(this).data ('forms')[index].subElements)
						{
							if ($(this).data ('forms')[index].subElements[subElementIndex].id == formElements[elementIndex].id)
							{
								var updatedElementDisplay = window.getComputedStyle (formElements[elementIndex]).display;
								$(this).data ('forms')[index].subElements[subElementIndex].display = updatedElementDisplay;
								$(this).data ('forms')[index].subElements[subElementIndex].readonly = formElements[elementIndex].hasAttribute ("readonly");
							}
						}
					}
				}
			}
		}
	}
	else
	{
		for (var monitoredForm in $(this).data ('forms'))
		{
			if ($(this).data ('forms') && $(this).data ('forms')[monitoredForm].form == form)
			{
				$(this).removeData ('forms');
			}
		}
	}
}



function GetFormElement (form)
{
	var formElement;

	if (form && form.nodeName)
	{
		formElement = $(form);
	}
	else
	{
		formElement = $("#" + form);
	}

	return formElement;
}



function ResetForm (form)
{
	if ($(this).data ('forms'))
	{
		var forms = $(this).data ('forms');

		for (var formData in forms)
		{
			if (forms[formData].form == form)
			{
				for (var index in forms[formData].subElements)
				{
					var subElementId = forms[formData].subElements[index].id;
					var subElementDisplay = forms[formData].subElements[index].display;
					var subElementReadonly = forms[formData].subElements[index].readonly;

					if (document.getElementById (subElementId))
					{
						document.getElementById (subElementId).removeAttribute ("formchanged");
						document.getElementById (subElementId).style.display = subElementDisplay;
						document.getElementById (subElementId).readOnly = subElementReadonly;
					}
				}
			}
		}
	}

	$('#' + form).get(0).reset();
	$('#' + form).data ("MonitorFormLastChangeState", "noChanges");
}

function MonitorFormChange (form, hasChangesCallback, noChangesCallback)
{
	$(document).ready (function()
	{
		var formFields = ["input", "textarea", "label", "fieldset", "select", "input[readonly]", "input[file]", "input[range]", "table", "div", "span"];
		var inputFields = [];
		var formElement = GetFormElement (form);

		$(formFields).each (function (index, fieldType)
		{
			if (formElement.find (fieldType))
			{
				inputFields.push (formElement.find (fieldType));
			}
		});

		//delay the bind one event loop by using setTimeout
		//This makes elements generated in the first event loop on page load available for binding
		//events against.
		function RunCallbacks (event)
		{
			if (GetFormHasChanges (form))
			{
				var monitoredElement = form;

				if (!monitoredElement.nodeName)
				{
					monitoredElement = document.getElementById (form);
				}

				var changeEvent = new Event ('formchanged');
				monitoredElement.dispatchEvent (changeEvent);

				//skip running if this call back was already called
				if (formElement.data ("MonitorFormLastChangeState") != "hasChanges")
				{
					hasChangesCallback();
					formElement.data ("MonitorFormLastChangeState", "hasChanges");
					event.target.setAttribute ("formchanged", "true");
				}
			}
			else
			{
				//skip running if we called this already
				if (formElement.data ("MonitorFormLastChangeState") != "noChanges")
				{
					noChangesCallback();
					formElement.data ("MonitorFormLastChangeState", "noChanges");
				}
			}
		}

		setTimeout (function()
		{
			for (var fieldIndex in inputFields)
			{
				$(inputFields[fieldIndex]).on ('change paste cut select input formupdated', function (event)
				{
					//this timeout guarantees the callbacks will fire in the next event loop, when at the time the changed
					// values of the form fields are available for comparing.
					setTimeout (RunCallbacks (event), 0);
					event.stopImmediatePropagation();
				});
			}

			if (typeof MutationObserver === 'function')
			{
				var formChangeObserver = new MutationObserver (function (mutations)
				{
					mutations.forEach (function (mutation)
					{
						if (mutation.type == 'childList')
						{
							$(mutation.target).on ('change paste cut select input formupdated', function (event)
							{
								//this timeout guarantees the callbacks will fire in the next event loop, when at the time the changed
								// values of the form fields are available for comparing.
								setTimeout (RunCallbacks (event), 0);
								event.stopImmediatePropagation();
							});
						}
					});
				});

				var formChangeConfiguration = {attributes: true, childList: true, subtree: true, characterData: true};

				if (document.getElementById (form))
				{
					formChangeObserver.observe (document.getElementById (form), formChangeConfiguration);
				}
			}

			UpdateFormDetails (form);
		}, 0);
	});
}

function ChangeButtonStateWhenFormChanges (form, button, enableCallback, disableCallback)
{
	var buttonElement;

	if (button && button.nodeName)
	{
		buttonElement = $(button);
	}
	else
	{
		buttonElement = $("#" + button);
	}

	MonitorFormChange (form,
		function()
		{
			buttonElement.removeClass ('disabledButton');
			buttonElement.addClass ('enabledButton');
			buttonElement.removeAttr ("disabled");
			buttonElement.prop ('tabindex', 0);

			if (typeof enableCallback != 'undefined')
			{
				enableCallback (buttonElement);
			}
		},
		function()
		{
			buttonElement.removeClass ('enabledButton');
			buttonElement.addClass ('disabledButton');
			buttonElement.attr ("disabled", "disabled");
			buttonElement.prop ("tabindex", -1);

			if (typeof disableCallback != 'undefined')
			{
				disableCallback (buttonElement);
			}
		}
	);
}


// Export
module.exports.UpdateFormDetails = UpdateFormDetails;
module.exports.ChangeButtonStateWhenFormChanges = ChangeButtonStateWhenFormChanges;
module.exports.GetFormCheckListing = GetFormCheckListing;
module.exports.ResetForm = ResetForm;
module.exports.GetFormHasChanges = GetFormHasChanges;
module.exports.MonitorFormChange = MonitorFormChange;

